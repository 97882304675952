import React, { useCallback, useEffect } from "react";
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    TextInput,
    ReferenceInput,
    ReferenceArrayInput,
    EditButton,
    ShowButton,
    Show,
    TabbedShowLayout,
    Edit,
    Create,
    SimpleForm,
    SelectInput,
    TopToolbar,
    CreateButton,
    ExportButton,
    required,
    useRecordContext,
    useCreatePath,
    Toolbar,
    useDataProvider,
    useNotify,
    ReferenceManyField,
    SingleFieldList,
    ChipField,
    TabbedForm,
    useEditController,
    RecordContextProvider,
} from 'react-admin';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import MuiTextField from '@mui/material/TextField';
import Slider from '@mui/material/Slider';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import { BluehillPagination } from './bluehillPagination';
import { AutoRefreshControl } from './components/AutoRefreshControl';
import AutocompleteArrayInput from './components/BaseAutocompleteArrayInput';
import { JsonField } from "react-admin-json-view";
import { JsonEditorInput } from "./components/JsonEditorInput.js";
import { useLocation } from "react-router";
import { ExecuteOperationButton, ExecuteOperationButtonUsingRecord } from "./components/ExecuteOperationButton";
import { SYNC_CLOUD_DATA_OPERATION_ID } from "./robotOperationConstants.js";
import { PostSubmitPopUp } from "./components/PostSubmitPopUp.js";
import { useFormContext } from "react-hook-form";

const filters = [
    <ReferenceArrayInput sort={{field:'storeId', order:'ASC'}}
        label="Store" source="storeIds" reference="stores"
        style={{minWidth:'120px'}} perPage={30} alwaysOn>
        <AutocompleteArrayInput queryFields={['storeName']} optionText="storeName" />
    </ReferenceArrayInput>,
];

const RobotStatusField = () => {
    const record = useRecordContext();
    if (record?.status === 'PAUSED' ||
        record?.status === 'PENDING' ||
        record?.status === 'DISCONNECTED' ||
        record?.status === 'FAILED' ||
        record?.status === 'PROTECTIVE_STOPPED' ||
        record?.status === 'EMERGENCY_STOPPED' ||
        record?.status === 'RELEASED_FROM_PROTECTIVE_STOPPED' ) {
        return (<span style={{ color: 'red' }}>{record && record['status']}</span>);
    } else {
        return (<span>{record && record['status']}</span>);
    }
};

function RobotListActions(props) {
    return (
        <TopToolbar>
            <AutoRefreshControl />
            <CreateButton/>
            <ExportButton/>
        </TopToolbar>
    );
}

const ListSpeakShowButton = (props) => {
    const createPath = useCreatePath();
    const record = useRecordContext();
    const path = createPath({ type: 'show', resource: 'robots', id: record.id });
    return <ShowButton {...props} to={`${path}/speak`} />;
};

export const RobotList = props => {
    return(
    <List {...props} filters={filters} pagination={<BluehillPagination />} actions={<RobotListActions />} perPage={50}>
        <Datagrid bulkActionButtons={false}>
            <TextField source="robotId" label="Robot Id"/>
            <TextField source="robotName"/>
            <ReferenceField label="Store" source="storeId" reference="stores">
                <TextField source="storeName" />
            </ReferenceField>
            <RobotStatusField label="Status" />
            <TextField source="mode"/>
            <ShowButton />
            <ListSpeakShowButton icon={<VolumeUpIcon />} label="Speak" location="speak" />
            <ExecuteOperationButtonUsingRecord operationId={SYNC_CLOUD_DATA_OPERATION_ID} label="SYNC CLOUD DATA" startIcon={<CloudSyncIcon />}
                operationName="Sync Cloud Data" />
            <EditButton />
        </Datagrid>
    </List>
)};

// https://github.com/Blue-Hill-Tech/BlueHillStoreMgrApp/blob/feat-upgrade-for-arm64/src/containers/Robots/Speaker.js

function SpeakForm() {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [words, setWords] = React.useState([
        "Hi, I'm jarvis.",
        'Would you like a cup of coffee?',
        'My Pleasure',
        'Thank you for shopping at Artly Coffee.',
    ]);
    const [text, setText] = React.useState('');
    const [volume, setVolume] = React.useState(50); // [0, 100]
    const record = useRecordContext();

    useEffect(() => {
        if (!record) {
            return;
        }
        dataProvider.getOne(`robots/~/modules/computer/properties/volume`, { id: record.id })
            .then(({ data }) => {
                setVolume(data.value);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [dataProvider, record]);

    const onSubmit = useCallback(async () => {
        if (!text) {
            return;
        }
        try {
            await dataProvider.create('robots/~/play_voice', {
                id: record.id,
                data: {
                    text
                }
            });
            notify(`Voice played!`);
            setText('');
        } catch (error) {
            notify(`Voice play failed!`);
        }
    }, [dataProvider, record.id, text, notify]);

    const onTextChange = useCallback((event) => {
        setText(event.target.value);
    }, []);

    const onAddWord = useCallback(() => {
        if (!text || words.includes(text)) {
            return;
        }
        setWords((prev) => [...prev, text]);
        setText('');
    }, [text, words]);

    const onUse = useCallback((index) => {
        setText(words[index]);
    }, [words]);

    const onRemoveWord = useCallback((index) => {
        setWords((prev) => prev.filter((_, i) => i !== index));
    }, []);

    const onVolumeChange = useCallback(async (event, value) => {
        try {
            await dataProvider.update(`robots/~/modules/computer/properties/volume`, {
                id: record.id,
                data: {
                    value
                }
            });
            setVolume(value);
        } catch (error) {
            console.error(error);
        }
    }, [dataProvider, record.id]);
    
    return (
        <SimpleForm toolbar={
            <Toolbar>
                <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                    <Button onClick={onSubmit} startIcon={<VolumeUpIcon />} variant="contained">Speak</Button>
                    <VolumeDownIcon />
                    <Slider
                        value={volume}
                        valueLabelDisplay="auto"
                        step={10}
                        marks
                        max={100}
                        onChange={onVolumeChange}
                        sx={{ width: '200px' }}
                    />
                    <VolumeUpIcon />
                </Stack>
            </Toolbar>
        }>
            <Stack direction="row" sx={{ width: '100%', alignItems: 'center' }}>
                <MuiTextField label="text" value={text} fullWidth onChange={onTextChange} />
                <Button onClick={onAddWord}>Add</Button>
            </Stack>
            <Stack sx={{ width: '100%' }}>
                {words.map((word, index) => (
                    <Stack direction="row" spacing={1} key={index} sx={{ alignItems: 'center' }}>
                        <blockquote style={{ flexGrow: 1, fontStyle: 'italic' }}>{word}</blockquote>
                        <Button onClick={() => onUse(index)}>Use</Button>
                        <Button onClick={() => onRemoveWord(index)}>Delete</Button>
                    </Stack>
                ))}
            </Stack>
        </SimpleForm>
    );
}

const useDisplayConfig = (robotId) => {
    const [displayConfig, setDisplayConfig] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const dataProvider = useDataProvider();
    const notify = useNotify();

    useEffect(() => {
        const fetchDisplayConfig = async () => {
            try {
                const response = await dataProvider.getOne('display_config', {
                    robotId: robotId
                });
                if (response.data.config === undefined) {
                    setDisplayConfig(null);
                } else {
                    setDisplayConfig(response.data.config);
                }
            } catch (error) {
                notify('Error loading display config', { type: 'error' });
                console.error('Error fetching display config:', error);
            } finally {
                setLoading(false);
            }
        };

        if (robotId !== undefined) {
            fetchDisplayConfig();
        }
    }, [dataProvider, robotId, notify]);

    return { displayConfig, setDisplayConfig, loading };
};

const RobotDisplayConfigEditTab = ({ jsonSubmitError }) => {
    const record = useRecordContext();
    const { displayConfig, loading } = useDisplayConfig(record?.robotId);

    if (loading) {
        return <div>Loading display configuration...</div>;
    }

    const formattedConfig = displayConfig ? JSON.stringify(displayConfig, null, 4) : '{\n\n}';

    return (
        <JsonEditorInput
            label="Display Config Json Editor"
            source="displayConfig"
            defaultValue={formattedConfig}
            serverError={jsonSubmitError}
            height="60vh"
        />
    );
};

export const RobotShow = props => {
    const location = useLocation();
    const robotId = location.pathname.split('/')[2];
    const { displayConfig } = useDisplayConfig(robotId);

    return (
        <Show {...props}>
            <TabbedShowLayout>
                <TabbedShowLayout.Tab label="Base" name="base">
                    <TextField source="robotId" label="Robot Id"/>
                    <TextField source="robotName"/>
                    <TextField source="configuration"/>
                    <TextField source="status"/>
                    <ReferenceField label="Store" source="storeId" reference="stores">
                        <TextField source="storeName" />
                    </ReferenceField>
                    <TextField source="mode"/>
                    <TextField source="jointStates"/>
                    <TextField source="degradedMode"/>
                    <ReferenceManyField label="Groups" reference="robot_group_relations" target="robotId">
                        <SingleFieldList>
                            <ChipField source="robotGroupKey" />
                        </SingleFieldList>
                    </ReferenceManyField>
                </TabbedShowLayout.Tab>
                <TabbedShowLayout.Tab label="Speak" name="speak" path="speak">
                    <SpeakForm />
                </TabbedShowLayout.Tab>
                <TabbedShowLayout.Tab label="Display" name="display" path="display_config">
                    <RecordContextProvider value={{ display_config: displayConfig }}>
                        <JsonField source="display_config" label="Display Config" />
                    </RecordContextProvider>
                </TabbedShowLayout.Tab>
            </TabbedShowLayout>

        </Show>
    );
};

export const RobotEdit = props => {
    const { save } = useEditController(props);
    const dataProvider = useDataProvider();
    const location = useLocation();
    const notify = useNotify();
    const [jsonSubmitError, setJsonSubmitError] = React.useState(null);
    const [dialogState, setDialogState] = React.useState({ popup: false });

    const onSubmit = useCallback(async function ({ robotId, displayConfig, ...data }) {
        if (location.pathname.split('/').pop() === 'display_config') {
            try {
                const robotIdNumber = Number(robotId);
                const displayConfigObject = JSON.parse(displayConfig);

                await dataProvider.update(`display_config`, {
                    robotId: robotIdNumber,
                    data: {config: displayConfigObject} // will be stringified by data provider
                });
                setJsonSubmitError(null);
                setDialogState({ popup: true })
                notify(`Robot updated!`);
            } catch (error) {
                setJsonSubmitError(error.message);
                notify(`Update failed!`);
            }
        } else { // default base tab save
            save(data);
        }
    }, [dataProvider, location.pathname, notify, save]);

    return (
        <Edit {...props}>
            <TabbedForm onSubmit={onSubmit}>
                <TabbedForm.Tab label="Base" name="base">
                    <TextInput source="robotId" label="Robot Id" />
                    <TextInput style={{ minWidth: '300px' }} source="robotName" />
                    <TextInput source="configuration" />
                    <ReferenceInput required label="Store Name" source="storeId" reference="stores" >
                        <SelectInput optionText="storeName" validate={[required()]} />
                    </ReferenceInput>
                    <SelectInput source="mode" choices={[
                        { id: 'LIVE', name: 'LIVE' },
                        { id: 'MAINTENANCE', name: 'MAINTENANCE' },
                    ]} />
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Display" name="display" path="display_config">
                    <RobotDisplayConfigEditTab jsonSubmitError={jsonSubmitError} />
                </TabbedForm.Tab>
            </TabbedForm>
            <PostSubmitPopUp dialogState={dialogState} setDialogState={setDialogState} dialogTitle="Sync Cloud Data?" 
                dialogTextContent="Successfully submitted action adjustment request.">
                <ExecuteOperationButtonUsingRecord operationId={SYNC_CLOUD_DATA_OPERATION_ID} label="SYNC CLOUD DATA" startIcon={<CloudSyncIcon />}
                    operationName="Sync Cloud Data" />
            </PostSubmitPopUp>

        </Edit>
    );
}

export const RobotCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput required source="robotId" label="Robot Id"/>
            <TextInput style={{minWidth: '300px'}} required source="robotName"/>
            <TextInput required source="configuration"/>
            <ReferenceInput required label="Store Name" source="storeId" reference="stores" >
                <SelectInput optionText="storeName" validate={[required()]}/>
            </ReferenceInput>
            <SelectInput source="mode" choices={[
                {id: 'LIVE', name: 'LIVE'},
                {id: 'MAINTENANCE', name: 'MAINTENANCE'},
            ]}/>
        </SimpleForm>
    </Create>
);
